body {
  &::before {
    content: "";
    position: fixed;
    z-index: -100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $gradient;
  }
}