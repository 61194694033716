body {
  color: $color-base-font;
  font-family: $font-family-regular;
  font-weight: $font-weight-regular;
  font-size: calc(#{$font-size-base} + #{$font-responsive-base});
  line-height: $line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  font-weight: $font-weight-heading;
  line-height: $line-height-heading;
  margin: 0 0 $spacing-small;
  text-transform: uppercase;
}

h1 {
  font-size: heading-scale(6);

  @include media($md-up) {
    font-size: heading-scale(7);
  }

  @include media($lg-up) {
    font-size: heading-scale(8);
  }
}

h2 {
  font-size: heading-scale(5);
  
  @include media($md-up) {
    font-size: heading-scale(6);
  }
  
  @include media($lg-up) {
    font-size: heading-scale(7);
  }
}

h3 {
  font-size: heading-scale(4);
  
  @include media($md-up) {
    font-size: heading-scale(5);
  }
  
  @include media($lg-up) {
    font-size: heading-scale(6);
  }
}

h4 {
  font-size: heading-scale(3);
  
  @include media($md-up) {
    font-size: heading-scale(4);
  }
  
  @include media($lg-up) {
    font-size: heading-scale(5);
  }
}

h5 {
  font-size: heading-scale(2);
  
  @include media($md-up) {
    font-size: heading-scale(3);
  }
  
  @include media($lg-up) {
    font-size: heading-scale(4);
  }
}

h6 {
  font-size: heading-scale(1);
  
  @include media($md-up) {
    font-size: heading-scale(2);
  }
  
  @include media($lg-up) {
    font-size: heading-scale(3);
  }
}

p {
  margin: 0 0 $spacing-small;
}

b,
strong {
  font-weight: bold;
}

a {
  color: currentColor;
  fill: currentColor;
  background: $color-primary;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

:link,
#{$all-buttons} {
  &:focus {
    outline: 0;
    box-shadow: 0 0 1px $color-primary;
  }
}

hr {
  border-bottom: $border-base;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $spacing-base 0;
}
