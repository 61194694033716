.player {
  width: 100%;
  border-radius: $border-base-radius;
  margin: 0 0 $spacing-base;

  &.is-hidden {
    display: none;
  }
}

.player__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    width: 100%;
  }
}

.player__button {
  margin: $spacing-small auto $spacing-base;
  display: block;
  transition: outline $animation-base-duration;
  background: $color-red;
  border: 2px solid $color-light;
  
  &.active, &:hover, &:active {
    background: $color-light;
    border: 2px solid $color-red;
    color: $color-red;
  }
}