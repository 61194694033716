.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: $spacing-small;
  // max-height: 100vh;
}

.page__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.page__title {
  text-align: center;
  width: 100%;
  margin: $spacing-small 0 $spacing-smaller;
}

.page__content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: $color-light;
  padding: $spacing-small;

  & > * {
    flex: 1;
  }

  & > div {
    display: flex;
    align-items: center;
    
    & > * {
      width: 100%;
    }
  }
}

.page__form {
  margin-bottom: 0;
  flex: 0;
}